<template>
  <div>
    <div
      class="
        p-16
        mt-16
        mx-6 mx-auto
        sm:p-28 sm:mt-28
        lg:px-8
        flex flex-col
        md:flex-row
        gap-20
        md:gap-24 md:gap-44
        items-center
        justify-center
      "
    >
      <div
        class="flex flex-col justify-start items-center md:justify-center gap-5"
      >
        <h2 class="text-2xl font-bold text-gray-800 sm:text-3xl">
          OUR PARTNERS
        </h2>
        <div class="flex flex-row items-center justify-center gap-3 md:gap-5">
          <a
            href="https://www.phocuswire.com/startup-stage-tern-gpt-technology-for-travel-planning"
            target="_blank"
            rel="noopener noreferrer"
            class="transition-opacity duration-300 group block"
          >
            <img
              src="../assets/featured_in/getyourguide.svg"
              alt="getyourguide"
              class="h-20 md:h-16 object-contain mx-auto cursor-pointer"
            />
          </a>
          <a
            href="https://www.phocuswire.com/startup-stage-tern-gpt-technology-for-travel-planning"
            target="_blank"
            rel="noopener noreferrer"
            class="transition-opacity duration-300 group block"
          >
            <img
              src="../assets/featured_in/freetour.svg"
              alt="freetour"
              class="h-12 md:h-10 object-contain mx-auto cursor-pointer"
            />
          </a>
          <a
            href="https://www.phocuswire.com/startup-stage-tern-gpt-technology-for-travel-planning"
            target="_blank"
            rel="noopener noreferrer"
            class="transition-opacity duration-300 group block"
          >
            <img
              src="../assets/featured_in/expedia.svg"
              alt="expedia"
              class="h-16 md:h-20 object-contain mx-auto cursor-pointer"
            />
          </a>
          <a
            href="https://www.phocuswire.com/startup-stage-tern-gpt-technology-for-travel-planning"
            target="_blank"
            rel="noopener noreferrer"
            class="transition-opacity duration-300 group block"
          >
            <img
              src="../assets/featured_in/viator.svg"
              alt="viator"
              class="h-6 md:h-8 object-contain mx-auto cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div class="flex flex-col items-center gap-5">
        <h2 class="text-2xl font-bold text-gray-800 sm:text-3xl">AS SEEN IN</h2>
        <a
          href="https://www.phocuswire.com/startup-stage-tern-gpt-technology-for-travel-planning"
          target="_blank"
          rel="noopener noreferrer"
          class="transition-opacity duration-300 group block"
        >
          <img
            src="../assets/featured_in/phocuswire.svg"
            alt="Phocuswire"
            class="h-10 md:h-16 mb-6 object-contain mx-auto cursor-pointer"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturedIn',
};
</script>

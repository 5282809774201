<template>
  <div class="main-how-it-works">
    <div class="mx-auto py-32 px-8 md:px-12">
      <h1 class="text-4xl md:text-5xl text-center font-bold">
        Your AI travel planner
      </h1>
      <div class="flex flex-row pt-10 justify-center">
        <div class="left-part w-1/2">
          <div>
            <img
              src="../assets/how_it_works/itinerary_list_desktop.png"
              class="main-image hidden md:block"
            />
            <img
              src="../assets/how_it_works/landing_mobile.png"
              class="md:hidden"
            />
          </div>
          <div
            class="
              flex flex-col-reverse
              md:flex-row
              items-center
              justify-center
              md:justify-start
              left-part-text
              pt-2
              md:pt-6
            "
          >
            <img
              src="../assets/how_it_works/stars.png"
              class="w-10 md:w-auto"
            />
            <p class="font-medium text-xs md:text-lg text-center md:text-left">
              AI ensures that every itinerary is perfectly suited to your tastes
            </p>
          </div>
        </div>
        <div class="right-part w-1/2">
          <div
            class="
              flex flex-col-reverse
              md:flex-row
              items-center
              justify-end
              right-part-text
            "
          >
            <p
              class="
                font-medium
                text-xs
                md:text-lg
                text-center
                md:text-right
                pb-2
                md:pb-6
              "
            >
              Tell us where you’re going, for how long, and any additional
              preferences
            </p>
            <img
              src="../assets/how_it_works/stars-2.png"
              class="w-10 md:w-auto"
            />
          </div>
          <div>
            <img
              src="../assets/how_it_works/itinerary_desktop.png"
              class="main-image hidden md:block"
            />
            <img
              src="../assets/how_it_works/itinerary_mobile.png"
              class="md:hidden"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HowItWorksSection',
};
</script>
<style scoped>
.main-how-it-works {
  background: linear-gradient(180deg, #ffffff 0%, #ffdd93 50%, #ffffff 100%);
}

.main-image {
  border-radius: 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  max-width: 605px;
  width: 100%;
}
@media (min-width: 768px) {
  .left-part,
  .right-part {
    width: auto;
  }
  .right-part {
    margin-left: -4%;
  }
  .right-part-text {
    padding-right: 10%;
  }

  .left-part-text p,
  .right-part-text p {
    max-width: 60%;
  }
}
</style>

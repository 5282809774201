<template>
  <div class="main-intro relative pb-20 md:pb-0 md:mb-12">
    <div
      class="
        flex flex-col
        md:flex-row
        px-8
        md:px-16
        xl:px-24
        py-12
        justify-between
      "
    >
      <div class="md:hidden relative">
        <img src="../assets/intro/main_mobile.png" class="w-full" />
        <div class="absolute plane_track_mobile_1">
          <img src="../assets/intro/plane_track_mobile_1.svg" />
        </div>
        <div class="absolute plane_track_mobile_2">
          <img src="../assets/intro/plane_track_mobile_2.svg" />
        </div>
      </div>
      <div class="justify-start pt-4 md:pt-12 left-side">
        <p class="text-6xl lg:text-7xl font-bold hidden md:block">
          Travel <br />
          smart.
        </p>
        <p class="text-5xl font-bold text-center md:hidden">Travel smart.</p>
        <p
          class="
            text-xs text-ternGray
            md:text-black md:text-xl
            lg:text-2xl
            font-medium
            pt-4
            md:py-8
            text-center
            md:text-left
          "
        >
          Let AI craft your perfect journey
        </p>
        <p class="text-xl lg:text-2xl font-medium hidden md:block">
          Discover your personalized itinerary in seconds
        </p>
        <div
          class="pt-4 md:pt-8 flex items-center justify-center md:justify-start"
        >
          <Button class="flex items-center justify-center" />
          <div class="absolute plane_track_mobile_3 md:hidden">
            <img src="../assets/intro/plane_track_mobile_3.svg" />
          </div>
        </div>
      </div>
      <div class="hidden md:block desktop_main_image relative">
        <img src="../assets/intro/main.png" class="w-full" />
        <div class="absolute plane_track_1">
          <img src="../assets/intro/plane_track_1.svg" />
        </div>
        <div class="absolute plane_track_2">
          <img src="../assets/intro/plane_track_2.svg" />
        </div>
      </div>
    </div>
    <div class="text-center w-full pt-4">
      <p class="md:hidden text-center">
        Discover your personalized <br />
        itinerary in seconds
      </p>
    </div>
    <div class="text-center w-full hidden md:block">
      <div
        class="flex flex-col justify-center items-center cursor-pointer gap-1"
        @click="goToHowItWorksSection"
      >
        <div>
          <img src="../assets/intro/carrot.svg" />
        </div>
        <p class="font-bold text-ternYellow opacity-53">Learn More</p>
      </div>
    </div>
    <div class="hidden md:block pink_cloud pink_cloud_1">
      <img src="../assets/intro/pink_cloud_right_end.svg" />
    </div>
    <div class="hidden md:block pink_cloud pink_cloud_2">
      <img src="../assets/intro/pink_cloud_top.svg" />
    </div>
    <div class="hidden md:block pink_cloud pink_cloud_3">
      <img src="../assets/intro/pink_cloud_left.svg" />
    </div>
    <div class="md:hidden pink_cloud mobile_pink_cloud_1">
      <img src="../assets/intro/mobile_pink_cloud_1.svg" />
    </div>
    <div class="md:hidden pink_cloud mobile_pink_cloud_2">
      <img src="../assets/intro/mobile_pink_cloud_2.svg" />
    </div>
    <div class="md:hidden pink_cloud mobile_pink_cloud_3">
      <img src="../assets/intro/mobile_pink_cloud_3.svg" />
    </div>
  </div>
</template>
<script>
import Button from './Buttons/TernButtonMain.vue';

export default {
  name: 'IntroSection',
  components: {
    Button,
  },
  methods: {
    goToHowItWorksSection() {
      this.$router.push({ hash: '#HowItWorks' });
    },
  },
};
</script>
<style scoped>
.main-intro {
  /* background-image: url(../assets/intro/plane_track.svg); */
  background-repeat: no-repeat;
  max-height: 100vh;
}
.pink_cloud {
  position: absolute;
  z-index: -1;
}
.pink_cloud_1 {
  bottom: -230px;
  right: 0;
}
.pink_cloud_2 {
  top: -115px;
  right: 5%;
}
.pink_cloud_3 {
  bottom: -135px;
}
.mobile_pink_cloud_1 {
  top: -80px;
}
.mobile_pink_cloud_2 {
  top: 25%;
  right: 0;
}
.mobile_pink_cloud_3 {
  bottom: 70px;
}
.plane_track_1 {
  top: -16%;
  left: -38%;
  z-index: -1;
  position: absolute;
  max-width: 70%;
}
.plane_track_2 {
  z-index: -1;
  top: 22%;
  left: 6%;
}
.plane_track_mobile_1 {
  top: -28%;
  right: -6%;
  z-index: -1;
}
.plane_track_mobile_2 {
  z-index: -1;
  bottom: -45%;
  left: -10%;
}
.plane_track_mobile_3 {
  z-index: -1;
  bottom: -20px;
  right: 56px;
}
@media (min-width: 1440px) {
  .left-side {
    width: 40%;
  }
  .desktop_main_image {
    width: 60%;
  }
}
@media (min-width: 767px) {
  .left-side {
    width: 30%;
  }
  .desktop_main_image {
    width: 52%;
  }
}
@media (max-width: 1680px) {
  .plane_track_2 {
    top: 10%;
  }
}
@media (max-width: 1280px) {
  .plane_track_1 {
    top: -14%;
    left: -39%;
  }
}
</style>

<template>
  <button class="curson-pointer" @click="redirectAndLogAmplitudeEvent">
    Start Planning
    <div class="star-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 784.11 815.53"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            class="fil0"
            d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="star-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 784.11 815.53"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            class="fil0"
            d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="star-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 784.11 815.53"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            class="fil0"
            d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="star-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 784.11 815.53"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            class="fil0"
            d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="star-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 784.11 815.53"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            class="fil0"
            d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="star-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 784.11 815.53"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            class="fil0"
            d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="plan">
      <img src="../../assets/intro/plane.svg" />
    </div>
    <div class="yellow-starts">
      <img src="../../assets/intro/yellow_stars.svg" />
    </div>
  </button>
</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';

export default {
  name: 'TernButton',
  methods: {
    redirectAndLogAmplitudeEvent() {
      window.location.href = 'https://tern.app/home';
      const apiKey =
        process.env.VUE_APP_ENDPOINTS === 'production'
          ? 'a8a56f173be65492fcf99607fa54c5cc'
          : 'eec0ac28984d63289b4a88e42518d23f';

      // Initialize Amplitude
      amplitude.init(apiKey);

      // Log the event
      amplitude.logEvent('Link Clicked (Landing Page)', {
        button: 'herosection',
      });
    },
  },
};
</script>

<style scoped>
button {
  position: relative;
  padding: 12px 50px;
  background: #fdad00;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  border: 2px solid #fdad00;
  border-radius: 200px;
  box-shadow: 0 0 0 #fdad00;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.star-1 {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 0 #58a8e1);
  z-index: -5;
  transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
}

.star-2 {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #58a8e1);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-3 {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #58a8e1);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-4 {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 0 #58a8e1);
  z-index: -5;
  transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-5 {
  position: absolute;
  top: 25%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #58a8e1);
  z-index: -5;
  transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-6 {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #58a8e1);
  z-index: -5;
  transition: all 0.8s ease;
}

.fil0 {
  fill: #58a8e1;
}

.plan {
  position: absolute;
  right: -40px;
  top: 120%;
  transform: translateY(-50%);
}

@keyframes moveUpAndDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.plan img {
  width: 70px;
  height: auto;
  display: block;
  animation: moveUpAndDown 3s ease-in-out infinite;
}
.yellow-starts {
  position: absolute;
  bottom: 0px;
  right: -108px;
}

button:hover {
  background: white;
  color: #58a8e1;
  border: 2px solid #a5c8e1;
  box-shadow: 0 0 10px #a5c8e1;
}
button:hover .star-1 {
  position: absolute;
  top: -80%;
  left: -30%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 0px #58a8e1);
  z-index: 2;
}

button:hover .star-2 {
  position: absolute;
  top: -25%;
  left: 10%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0px #58a8e1);
  z-index: 2;
}

button:hover .star-3 {
  position: absolute;
  top: 55%;
  left: 25%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0px #58a8e1);
  z-index: 2;
}

button:hover .star-4 {
  position: absolute;
  top: 30%;
  left: 80%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 0px #58a8e1);
  z-index: 2;
}

button:hover .star-5 {
  position: absolute;
  top: 25%;
  left: 115%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0px #58a8e1);
  z-index: 2;
}

button:hover .star-6 {
  position: absolute;
  top: 5%;
  left: 60%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0px #58a8e1);
  z-index: 2;
}

button:hover .plan {
  display: none;
}
@media (max-width: 768px) {
  button {
    padding: 12px 30px;
    font-size: 14px;
  }
  .plan {
    right: -26px;
    top: 105%;
  }
  .plan img {
    width: 45px;
  }
  .yellow-starts {
    bottom: 25px;
    right: -60px;
  }
  .yellow-starts img {
    width: 40px;
    height: 40px;
  }
}
</style>

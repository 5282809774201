<template>
  <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-28 lg:px-8 relative">
    <div class="mx-auto max-w-2xl text-center mb-4">
      <span class="text-base md:text-2xl font-bold text-ternGray">
        See what others are saying.
      </span>
      <p
        class="
          mt-4
          md:mt-6
          text-3xl
          font-bold
          tracking-tight
          sm:text-6xl sm:leading-tight
        "
      >
        {{ header }}
      </p>
    </div>

    <!-- Scrollable container -->
    <div class="overflow-hidden mt-12 md:mt-24">
      <div
        @mousedown="pauseScrolling"
        @mouseup="resumeScrolling"
        @mouseleave="resumeScrolling"
        @touchstart="pauseScrolling"
        @touchend="resumeScrolling"
        class="animate-slide whitespace-nowrap cursor-pointer"
      >
        <template v-for="i in 2" :key="`group-${i}`">
          <img
            src="../assets/testimonials/t1.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t2.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t3.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t4.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t5.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t6.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t7.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
          <img
            src="../assets/testimonials/t8.jpg"
            class="inline-block w-auto max-h-32 rounded-lg shadow mr-8"
            alt="..."
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Testimonials',
  data() {
    return {
      header: 'Join thousands of users traveling smarter',
    };
  },
  methods: {
    pauseScrolling() {
      this.$el.querySelector('.animate-slide').style.animationPlayState =
        'paused';
    },
    resumeScrolling() {
      this.$el.querySelector('.animate-slide').style.animationPlayState =
        'running';
    },
  },
};
</script>

<style scoped>
@keyframes slide {
  0%,
  25% {
    transform: translate3d(25, 0, 0);
  }
  50% {
    transform: translate3d(-50%, 0, 0);
  }
  75% {
    transform: translate3d(-75%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-slide {
  animation: slide 30s linear infinite;
  will-change: transform;
}
</style>

<template>
  <div class="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
    <div
      class="
        relative
        isolate
        overflow-hidden
        text-center text-white
        px-6
        py-40
        shadow-2xl
        md:px-24
        card-bg-image
      "
    >
      <div class="absolute inset-0"></div>
      <h2
        class="
          mx-auto
          max-w-2xl
          text-4xl
          font-bold
          sm:text-6xl
          leading-tight
          z-10
        "
      >
        {{ header }}
        <br />
        {{ subHeader }}
      </h2>
      <p
        class="
          mx-auto
          mt-6
          mb-10
          max-w-xs
          md:max-w-xl md:text-2xl
          leading-tight
          z-10
        "
      >
        {{ subText }}
      </p>
      <div class="flex justify-center z-10 md:pt-6">
        <TernButton />
      </div>
    </div>
  </div>
</template>

<script>
import TernButton from './Buttons/TernButtonCTA.vue';

export default {
  name: 'Testimonials',
  components: {
    TernButton,
  },
  data() {
    return {
      header: 'Ready to try it',
      subHeader: `for yourself?`,
      subText: `Turn your travel fantasies into reality, and craft your personalized trip in seconds with Tern.`,
    };
  },
};
</script>

<style scoped>
.card-bg-image {
  background-image: url('../assets/cta.webp');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 20px;
}

.card-bg-image::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: inherit;
  z-index: -1;
}

@media (max-width: 768px) {
  .card-bg-image {
    border-radius: 0;
  }
}
</style>

<template>
  <router-view />
</template>

<style>
#app {
  font-family: 'AirbnbCereal_W_Bk';
  -webkit-font-smoothing: antialiased;
}
</style>

<template>
  <div class="w-full main-group">
    <div class="mx-auto py-24 max-w-7xl px-12 md:py-28 md:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <p
          class="
            hidden
            md:block md:text-2xl md:leading-tight
            font-medium
            text-ternGray
            mb-4
          "
        >
          {{ header }}
        </p>
        <p class="text-3xl md:text-6xl leading-tight font-bold">
          {{ mainHeader }}
        </p>
        <p class="text-xs text-ternGray font-medium pt-4 md:hidden">
          {{ subheader }}
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 md:mt-20">
        <div
          v-for="(result, index) in results"
          :key="index"
          @mouseenter="setActive(index, true)"
          @mouseleave="setActive(index, false)"
          @click="setActive(index, true)"
          class="
            group
            max-w-sm
            mx-auto
            overflow-hidden
            bg-white
            border border-gray-200
            rounded-lg
            shadow-lg
            dark:bg-gray-800 dark:border-gray-700
          "
        >
          <div class="relative">
            <img
              class="
                object-cover
                w-full
                transition-transform
                duration-300
                transform
                group-hover:scale-105
              "
              :src="getImgUrl(result.image)"
              alt=""
            />
            <div
              class="
                absolute
                bottom-0
                inset-x-0
                p-5
                bg-gradient-to-t
                from-black
                via-transparent
              "
            >
              <h5 class="text-xl font-bold tracking-tight text-white">
                {{ result.heading }}
              </h5>
              <div
                v-if="isActive(index)"
                class="
                  transition-opacity
                  duration-500
                  ease-in
                  opacity-0
                  group-hover:opacity-100
                "
              >
                <p class="mb-3 font-normal text-gray-100">
                  {{ result.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <Button class="mt-12 sm:mt-16 w-3/5 md:w-2/5 lg:w-1/5" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Buttons/TernButtonQuiz.vue';

export default {
  name: 'Quiz',
  components: {
    Button,
  },
  data() {
    return {
      header: 'Need help personalizing your recommendations?',
      mainHeader: 'Find your travel personality',
      subheader: 'Personalize your recommendations with your result.',
      activeIndex: -1,
      results: [
        {
          image: 'urban_artistic_voyager.png',
          heading: 'Foodie Voyager',
          description: `Your ideal destination is probably the graffiti-filled streets of Berlin, the jazz clubs of New Orleans, or the food markets of Bangkok.`,
        },
        {
          image: 'serene_adventurer.png',
          heading: 'Serene Adventurer',
          description: `Consider the serene fjords of Norway for a peaceful kayak trip or the vibrant markets of Marrakech for a taste of the exotic. Ever thought about a tranquil retreat in Bali?`,
        },
        {
          image: 'artistic_wanderer.png',
          heading: 'Artistic Wanderer',
          description:
            'Your love for creativity, music, and literature suggests a trip to the vibrant streets of Berlin or the tranquil landscapes of New Zealand',
        },
      ],
    };
  },
  methods: {
    setActive(index, isActive) {
      this.activeIndex = isActive ? index : -1;
    },
    isActive(index) {
      return this.activeIndex === index;
    },
    getImgUrl(img) {
      const images = require.context('../assets/personality_quiz/');
      return images(`./${img}`);
    },
  },
};
</script>

<style scoped>
.group:hover .object-cover,
.group:active .object-cover {
  transform: scale(1.05);
}

.group .bg-gradient-to-t {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  transition: background-image 1s ease-in-out;
}

.group .description {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  will-change: opacity;
}

.group:hover .description,
.group:active .description {
  opacity: 1;
}
.main-group {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.17) 0%,
    rgba(255, 171, 187, 0.17) 40%,
    rgba(253, 173, 0, 0.17) 67%,
    rgba(255, 255, 255, 0.17) 100%
  );
}
@media (hover: hover) {
  .group:hover .description {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .main-group {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.17) 0%,
      rgba(255, 171, 187, 0.17) 22%,
      rgba(253, 173, 0, 0.17) 82%,
      rgba(255, 255, 255, 0.17) 100%
    );
  }
}
</style>

<template>
  <Navbar />
  <IntroSection />
  <section id="HowItWorks">
    <HowItWorksSection />
  </section>
  <section id="Features" class="overflow-hidden">
    <FeatureSection />
  </section>
  <Quiz />
  <!-- <section id="TravelAgent" class="section-color-2">
    <TravelAgent />
  </section> -->
  <section id="Testimonials">
    <Testimonials />
  </section>
  <FeaturedIn class="section-color-3" />
  <section id="FAQ">
    <FAQ />
  </section>
  <CTA />
  <Footer />
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue';
import Quiz from '@/components/Quiz.vue';
// import TravelAgent from '@/components/TravelAgent.vue';
import Testimonials from '@/components/Testimonials.vue';
import FeaturedIn from '../components/FeaturedIn.vue';
import FAQ from '@/components/FAQ.vue';
import CTA from '@/components/CTA.vue';
import Footer from '@/components/Footer.vue';
import FeatureSection from '@/components/FeatureSection.vue';
import HowItWorksSection from '@/components/HowItWorksSection.vue';
import IntroSection from '../components/IntroSection.vue';

export default {
  name: 'LandingPage',
  components: {
    Navbar,
    FeatureSection,
    Quiz,
    // TravelAgent,
    Testimonials,
    FeaturedIn,
    FAQ,
    CTA,
    Footer,
    HowItWorksSection,
    IntroSection,
  },
};
</script>

<style scoped>
.section-color-2 {
  background: #eff7fc;
}

.section-color-3 {
  background: linear-gradient(to top, #e8f4fb 0%, #ffffff 100%);
}
</style>
